import { CalendarSliderItem } from '@app/types'

export const timeAgo = (date: Date) => {
  const seconds = Math.floor(((new Date()).getTime() - date.getTime()) / 1000)
  const interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + ' years'
  }
  if (interval === 1) {
    return interval + ' year'
  }

  const months = Math.floor(seconds / 2628000)
  if (months > 1) {
    return months + ' months'
  }
  if (months === 1) {
    return months + ' month'
  }

  const days = Math.floor(seconds / 86400)
  if (days === 7) {
    return '1 Week'
  }
  if (days > 1) {
    return days + ' days'
  }
  if (days === 1) {
    return days + ' day'
  }

  const hours = Math.floor(seconds / 3600)
  if (hours > 1) {
    return hours + ' hours'
  }
  if (hours === 1) {
    return hours + ' hour'
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes > 1) {
    return minutes + ' minutes'
  }
  if (minutes === 1) {
    return minutes + ' minute'
  }

  return 'just now'
}

export const THIS_YEAR = Number(new Date().getFullYear())

export const THIS_MONTH = Number(new Date().getMonth()) + 1
export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000
export const WEEK_DAYS_2_LETTERS = [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su' ]
export const WEEK_DAYS_3_LETTERS = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ]

export const MOTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const getShortMonthNames = () => MOTHS_LIST.map(name => name.substring(0,3))

export const CALENDAR_WEEKS = 6

export const zeroPad = (value, length) => {
  return `${value}`.padStart(length, '0')
}

export const beginningOfDay = (date: Date) => {
  if (!isDate(date)) return null

  return new Date(date.toDateString())
}

export const beginningOfMonth = (date: Date = new Date()) => {
  if (!isDate(date)) return null
  date.setMonth(date.getMonth() - 1)
  return new Date(date.toDateString())
}

export const getYearsList = (initialYear: number = THIS_YEAR) => {
  const yearsList = []
  for (let i = THIS_YEAR; i >= THIS_YEAR - 100; i--) {
    const yearSelectItem = {
      id: i,
      title: i.toString(),
      selected: initialYear === i
    }
    yearsList.push(yearSelectItem)
  }
  return yearsList
}

export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [ 4, 6, 9, 11 ]
  const leapYear = year % 4 === 0
  return month === 2
    ? leapYear
      ? 29
      : 28
    : months30.includes(month)
      ? 30
      : 31
}

export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return Number(new Date(`${year}-${zeroPad(month, 2)}-01`).getDay())
}

export const isDate = date => {
  const isDate = Object.prototype.toString.call(date) === '[object Date]'
  const isValidDate = date && !Number.isNaN(date.valueOf())

  return isDate && isValidDate
}

export const isSameMonth = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false
  const basedateMonth = Number(basedate.getMonth()) + 1
  const basedateYear = basedate.getFullYear()
  const dateMonth = Number(date.getMonth()) + 1
  const dateYear = date.getFullYear()
  return (Number(basedateMonth) === Number(dateMonth)) && (Number(basedateYear) === Number(dateYear))
}

export const isSameDay = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false
  const basedateDate = basedate.getDate()
  const basedateMonth = Number(basedate.getMonth()) + 1
  const basedateYear = basedate.getFullYear()
  const dateDate = date.getDate()
  const dateMonth = Number(date.getMonth()) + 1
  const dateYear = date.getFullYear()
  return (
    Number(basedateDate) === Number(dateDate)) &&
    (Number(basedateMonth) === Number(dateMonth)) &&
    (Number(basedateYear) === Number(dateYear)
    )
}

export const isSameTime = (date, basedate = new Date()) => {
  if (!isSameDay(date, basedate)) return false
  return getDateTimeAsString(date) === getDateTimeAsString(basedate)
}

export const isPast = (date: Date) => {
  const now = new Date()
  return !isSameDay(date, now) && date < now
}

export const isFuture = (date: Date) => {
  const now = new Date()
  return !isSameDay(date, now) && date > now
}

/*
 * return format <DD.MM.YY>
 */
export const getDateISO = (date = new Date) => {
  if (!isDate(date)) return null
  return [
    zeroPad(Number(date.getDate()), 2),
    zeroPad(Number(date.getMonth()) + 1, 2),
    date.getFullYear().toString().substring(2),
  ].join('.')
}

/**
 * return format <DD.MM.YYYY>
 */
export const getDateStringWithFullYear = (date: Date) => {
  if (!isDate(date)) return ''
  return [
    zeroPad(Number(date.getDate()), 2),
    zeroPad(Number(date.getMonth()) + 1, 2),
    date.getFullYear().toString(),
  ].join('.')
}
/**
 * return format <DD/MM/YYYY>
 */
export const getDateStringWithFullYearRefomated = (date: Date, delimiter='/') => {
  if (!isDate(date)) return ''
  return [
    zeroPad(Number(date.getDate()), 2),
    zeroPad(Number(date.getMonth()) + 1, 2),
    date.getFullYear().toString(),
  ].join(delimiter)
}

/**
 * return format <DD/MM/YYYY>
 */
export const getDateStringWithFullYearReformat = (date: Date) => {
  if (!isDate(date)) return ''
  return [
    zeroPad(Number(date.getDate()), 2),
    zeroPad(Number(date.getMonth()) + 1, 2),
    date.getFullYear().toString(),
  ].join('/')
}

/*
 * return format <DD MonthName YYYY>
 */
export const getDateStringWithMonthName = (date = new Date) => {
  if (!isDate(date)) return null
  return [
    zeroPad(Number(date.getDate()), 2),
    MOTHS_LIST[Number(date.getMonth())],
    date.getFullYear(),
  ].join(' ')
}

/*
 * return format <HH:MM>
 */
export const getDateTimeAsString = (date: Date): string => {
  if (!isDate(date)) return ''
  return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
}

/**
 * prepares date for backend format
 * @param date
 */
export const getDayAsString = (date: Date): string => {
  if (isDate(date)) {
    return `${zeroPad(date.getMonth() + 1, 2)}/${zeroPad(date.getDate(), 2)}/${date.getFullYear()}`
  }
  return ''
}

export const getDateAsObjectWithStrings = (date: Date) => {
  if (!isDate(date)) return null
  return {
    day: zeroPad(date.getDate(), 2),
    month: getShortMonthNames()[date.getMonth()],
    weekday: WEEK_DAYS_3_LETTERS[date.getDay()],
  }
}

export const getMonthsList = (initialMonthId: number = THIS_MONTH) => {
  return MOTHS_LIST.map((monthTitle, index) => ({
    id: String(index + 1),
    title: monthTitle,
    selected: initialMonthId === (index + 1)
  }))
}

export const getPreviousMonth = (month: number, year: number) => {
  const prevMonth = (month > 1) ? month - 1 : 12
  const prevMonthYear = (month > 1) ? year : year - 1
  return { month: prevMonth, year: prevMonthYear }
}

export const getNextMonth = (month: number, year: number) => {
  const nextMonth = (month < 12) ? month + 1 : 1
  const nextMonthYear = (month < 12) ? year : year + 1
  return { month: nextMonth, year: nextMonthYear }
}

export const getMonthNameByNumber = (monthNumber: number) => MOTHS_LIST[monthNumber - 1]

export const getGridDates = (month = THIS_MONTH, year = THIS_YEAR): {
  year: number;
  month: string;
  day: string;
  date: Date;
}[] => {
  const prepareDate = (day: number, month: number, year: number) => {
    const preparingDate = beginningOfDay(new Date())
    preparingDate.setFullYear(year, month, day)
    return preparingDate
  }

  const monthDays = getMonthDays(month, year)
  const monthFirstDay = getMonthFirstDay(month, year) || 7

  const daysFromPrevMonth = monthFirstDay - 1
  const daysFromNextMonth = (CALENDAR_WEEKS * 7) - (daysFromPrevMonth + monthDays)

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year)
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year)
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear)

  const prevMonthDates = [ ...new Array(daysFromPrevMonth) ].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth)
    return {
      year: prevMonthYear,
      month: zeroPad(prevMonth, 2),
      day: zeroPad(day, 2),
      date: prepareDate(day, prevMonth - 1, prevMonthYear),
    }
  })

  const thisMonthDates = [ ...new Array(monthDays) ].map((n, index) => {
    const day = index + 1
    return {
      year,
      month: zeroPad(month, 2),
      day: zeroPad(day, 2),
      date: prepareDate(day, month - 1, year),
    }
  })

  const nextMonthDates = [ ...new Array(daysFromNextMonth) ].map((n, index) => {
    const day = index + 1
    return {
      year: nextMonthYear,
      month: zeroPad(nextMonth, 2),
      day: zeroPad(day, 2),
      date: prepareDate(day, nextMonth - 1, nextMonthYear),
    }
  })

  return [ ...prevMonthDates, ...thisMonthDates, ...nextMonthDates ]
}

export const getDatesListForSlider = (date: Date, daysBefore: number, daysAfter: number): CalendarSliderItem[] => {
  if (!isDate(date)) return []

  const currentDate = beginningOfDay(date)
  const today = beginningOfDay(new Date())
  const { weekday, day, month } = getDateAsObjectWithStrings(currentDate)
  const currentItem: CalendarSliderItem = {
    date: currentDate,
    firstRowText: weekday,
    secondRowText: day + ' ' + month,
  }

  const previousDays: CalendarSliderItem[] = []
  for (let i = daysBefore; i >= 1; i--) {
    const previousDate = new Date(Number(currentDate) - DAY_MILLISECONDS * i)
    if (Number(previousDate) - Number(today) < 0) continue
    const { weekday: w, day: d, month: m } = getDateAsObjectWithStrings(previousDate)
    previousDays.push({
      date: previousDate,
      firstRowText: w,
      secondRowText: d + ' ' + m,
    })
  }

  const nextDays: CalendarSliderItem[] = []
  for (let i = 1; i <= daysAfter; i++) {
    const nextDate = new Date(Number(currentDate) + DAY_MILLISECONDS * i)
    const { weekday: w, day: d, month: m } = getDateAsObjectWithStrings(nextDate)
    nextDays.push({
      date: nextDate,
      firstRowText: w,
      secondRowText: d + ' ' + m,
    })
  }

  return [ ...previousDays, currentItem, ...nextDays ]
}
export const checkIfAgeIsAbove18 = (date: Date): boolean => {
  if (!isDate(date)) false

  const birthdayDate = new Date(date)

  const year = birthdayDate.getFullYear()
  const currentYear = new Date().getFullYear()
  const age = currentYear - year

  return age > 18
}
export const weekAgoDate = new Date(Number(beginningOfDay(new Date())) - (7 * DAY_MILLISECONDS))
export const getPeriodsListForSlider = (): CalendarSliderItem[] => {
  const today = beginningOfDay(new Date())
  const todayItem: CalendarSliderItem = { date: today, firstRowText: 'Today', id: 'today' }

  const weekAgoItem: CalendarSliderItem = { date: weekAgoDate, firstRowText: '1 week', id: 'oneWeek' }

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(today.getMonth() + 1, today.getFullYear())
  const monthAgoDate = new Date(`${prevMonthYear}-${zeroPad(prevMonth, 2)}-${zeroPad(today.getDate(), 2)}`)
  const monthAgoItem: CalendarSliderItem = { date: monthAgoDate, firstRowText: '1 month', id: 'oneMonth' }

  const { month: twoMonthAgo, year: twoMonthYearAgo } = getPreviousMonth(prevMonth, prevMonthYear)
  const { month: threeMonthAgo, year: threeMonthYearAgo } = getPreviousMonth(
    twoMonthAgo,
    twoMonthYearAgo
  )
  const threeMonthAgoDate = new Date(`${threeMonthYearAgo}-${zeroPad(threeMonthAgo, 2)}-${zeroPad(today.getDate(), 2)}`)
  const threeMonthAgoItem: CalendarSliderItem = { date: threeMonthAgoDate, firstRowText: '3 months', id: 'threeMonth' }

  return [ todayItem, weekAgoItem, monthAgoItem, threeMonthAgoItem, { date: null, firstRowText: 'All', id: 'all' } ]
}

/*
 * return format <Today> | <Yesterday> | <DD.MM.YYYY>
 */
export const getFormattedTimeBasedOnPeriod =(date, delimiter='/')=> {
  const now = new Date()
  const period = new Date(date)
  const yesterday = new Date(now)
  yesterday.setDate(now.getDate() - 1)

  if (
    period.getDate() === now.getDate() &&
    period.getMonth() === now.getMonth() &&
    period.getFullYear() === now.getFullYear()
  ) {
    return 'Today'
  } else if (
    period.getDate() === yesterday.getDate() &&
    period.getMonth() === yesterday.getMonth() &&
    period.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Yesterday'
  } else {
    return getDateStringWithFullYearRefomated(period, delimiter)
  }
}

/*
 * return format <MonthName DD YYYY>
 */
export const getDateStringWithMonthNamePrior = (date = new Date) => {
  if (!isDate(date)) return null
  return [
    MOTHS_LIST[Number(date.getMonth())].substring(0, 3)+'.',
    zeroPad(Number(date.getDate()), 2),
  ].join(' ')
}

export const formatISODateToString = (inputDate) => {
  const date = new Date(inputDate)

  const day = date.getUTCDate().toString().padStart(2, '0')
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const year = date.getUTCFullYear().toString()

  const hours = date.getUTCHours().toString().padStart(2, '0')
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}`
}
